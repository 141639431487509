<template>
  <div>
    <AlertModal
        :title="alertTitle"
        :content="alertContent"
        :is-open="alertCheck"
        :is-info="true"
        :confirm-click-btn="alertClickAction"></AlertModal>

    <!--PC Version.-->

    <div id="wrap" v-if="!$isMobile()">
      <div class="header">
        <div class="container">
          <div id="logo-wrap">
            <div id="logo" @click="moveHome"></div>
          </div>
          <div id="gnb">

            <div>
              <router-link :to="{path: `/about/intro`}">
                <span class="menu"
                      :class="{active:this.$router.currentRoute.matched[0].path.includes('about')}">About</span>
              </router-link>
            </div>

            <div class="dropdown-menu-wrap" @mouseenter="openDropdownMarket" @mouseleave="closeDropdownMarket">
              <router-link :to="{path: `/market/list?page=1&order=3&sub_category_no=217,`}">
                <span class="menu"
                      :class="{active:this.$router.currentRoute.matched[0].path.includes('market')}">Market</span>
              </router-link>
              <div class="dropdown-menu-list">
                <ul>
                  <li>
                    <router-link :to="{path: `/market/list?page=1&order=3&sub_category_no=217,`}"
                                 :class="{active:this.$router.currentRoute.matched[1].path.endsWith('/market/list')}">
                      Wine NFT
                    </router-link>
                  </li>
                  <!--<li>
                    <router-link :to="{path: `/market/list/sale`, query: {...$route.query}}" :class="{active:this.$router.currentRoute.matched[1].path.endsWith('/market/list/sale')}" >Wine NFT Buy Now</router-link>
                  </li>-->
                  <li>
                    <router-link :to="{path: `/market/membership/list?page=1&order=3&sub_category_no=219,`} "
                                 :class="{active:this.$router.currentRoute.matched[1].path.endsWith('/membership/list')}">
                      Membership NFT
                    </router-link>
                  </li>
                  <!--<li>
                    <router-link :to="{path: `/market/membership/list/sale`, query: {...$route.query}} " :class="{active:this.$router.currentRoute.matched[1].path.endsWith('/membership/list/sale')}">Membership NFT Buy Now</router-link>
                  </li>-->
                </ul>
              </div>
            </div>
            <!--<div @click="basicAlert('준비중 입니다.')">
              <span class="menu" style="cursor: pointer;">Market</span>
            </div>-->

            <div>
              <router-link :to="{path: `/membership`}">
                <span class="menu" :class="{active:this.$router.currentRoute.matched[0].path.includes('membership')}">Membership</span>
              </router-link>
            </div>

            <div class="dropdown-menu-wrap" @mouseenter="openDropdown" @mouseleave="closeDropdown">
              <router-link :to="{path: `/cs/guide`}">
                <span class="menu"
                      :class="{active:this.$router.currentRoute.matched[0].path.includes('cs')}">Help</span>
              </router-link>
              <div class="dropdown-menu-list">
                <ul>
                  <li>
                    <router-link :to="{path: `/cs/notice`}"
                                 :class="{active:this.$router.currentRoute.matched[1].path.includes('notice')}">Notice
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{path: `/cs/faq`} "
                                 :class="{active:this.$router.currentRoute.matched[1].path.includes('faq')}">FAQ
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{path: `/cs/guide`}"
                                 :class="{active:this.$router.currentRoute.matched[1].path.includes('guide')}">User
                      Guide
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{path: `/cs/press`}"
                                 :class="{active:this.$router.currentRoute.matched[1].path.includes('press')}">Press
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div class="container-right">
          <div id="search">
            <input type="text" class="header-search-input" placeholder="원하는 와인명으로 검색" v-model="searchKeyword"
                  @keyup.13="wineMarketSearch()"/>
          </div>

          <div id="my">

            <!-- Todo: 병합버전에서는 다시 살려놓기 -->

            <!------ 로그인 안되어 있을 경우 로그인 버튼 표시 ------>
            <IconButton
                v-if="!auth.isLogin"
                :content="'Connect'"
                :class-list="'primary-btn w160'"
                :icon="require('@/assets/images/icon/ic-wallet-white.png')"
                class="login-btn"
                :onClick="connectWalletLogin"
            ></IconButton>

            <!------ 로그인 되어 있을 경우 ------>
            <router-link :to="{path: `/users/mypage`}">
              <div v-if="auth.isLogin" class="in-mypage"><!-- 마이페이지 > 나의 NFT 리스트 가기 버튼 --></div>
            </router-link>
            <router-link :to="{path: `/users/minter/create/step1`}">
              <!-- NFT 생성 화면으로 가는 버튼 -->
              <IconButton
                  v-if="auth.isLogin && auth.isMinter"
                  :content="'Create'"
                  :class-list="'primary-btn w160'"
                  :icon="require('@/assets/images/icon/ic-create-white.png')"
                  class="login-btn"
              ></IconButton>
            </router-link>
          </div>

        </div>
      </div>
    </div>


    <!-- Mobile Version. -->
    <!-------------------------------------------------------------->
    <div id="wrap-mo" v-if="$isMobile()">
      <div v-if="!mobileSearch" class="non-search-wrap">
        <div class="container-mo">
          <div id="logo-wrap">
            <div id="logo" @click="moveHome"></div>
          </div>
        </div>
        <div class="container-right">
          <div class="quick-menu">
            <div class="ic-search-wrap">
              <div class="ic-search" @click="mobileSearchOn"></div>
            </div>
            <div class="ic-mypage-wrap">
              <router-link class="menu" :to="{path: `/users/mypage`}">
                <div class="ic-mypage"></div>
              </router-link>
            </div>
            <div class="hamburger-wrap">
              <div class="ic-hamburger"
                   :class="{'ic-hamburger-menu': !mobileMenuToggle.isOpen, 'ic-close-menu': mobileMenuToggle.isOpen}"
                   @click="changeMenuState()">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search-wrap" v-if="mobileSearch">
        <div class="search">
          <input type="search" placeholder="원하는 와인명으로 검색" class="header-search-input" v-model="searchKeyword"
                 @keyup.13="wineMarketSearch()">
          <div class="search-close" @click="mobileSearchOff"></div>
        </div>
      </div>
    </div>
    <VueSlideToggle class="slide-gnb-toggle" :open="mobileMenuToggle.isOpen" :duration="200" v-if="$isMobile()">

      <div class="menu-wrap">
        <div v-for="nav in navList" :key="nav.routeName">
          <router-link v-if="nav.path"
                       class="text-[18px] font-[Suit-Medium] h-[52px] flex items-center"
                       :to="{path: nav.path}"
          >
            <div :style="{color: nav.active ? '#A8221A':'#000', fontFamily: nav.active ? 'Suit-Bold' : 'Suit-Medium'}">
              {{ nav.name }}
            </div>
          </router-link>
          <div v-else>
            <div
                class="text-[18px] font-[Suit-Medium] h-[52px] flex items-center flex justify-between"
                @click="nav.active = !nav.active"
            >
              <span>{{ nav.name }}</span>
              <img
                  class="w-[16px] h-[16px] transition-transform	"
                  src="@/assets/images/icon/icon-mobile-header-arrow.svg"
                  :style="{transform: nav.active ? 'rotate(0deg)' : 'rotate(180deg)'}"
              />
            </div>
            <VueSlideToggle :open="nav.active">
              <div class="pl-[32px]">
                <router-link v-for="child in nav.children" :key="child.routeName"
                             class="text-[16px] font-[Suit-Medium] h-[44px] flex items-center"
                             :to="{path: child.path}"
                >
                  <div
                      :style="{color: child.active ? '#A8221A':'#303030', fontFamily: child.active ? 'Suit-Bold' : 'Suit-Medium'}">
                    {{ child.name }}
                  </div>
                </router-link>
              </div>

            </VueSlideToggle>

          </div>




        </div>
        <IconButton
                      v-if="!auth.isLogin"
                      :content="'Connect'"
                      :class-list="'primary-btn w160'"
                      :icon="require('@/assets/images/icon/ic-wallet-white.png')"
                      class="login-btn"
                      :onClick="connectWalletLogin"
                      style="width: 100%;"
                  ></IconButton>
      </div>


      <!--        <div class="menu-wrap">
                <router-link class="text-[18px] font-[Suit-Medium] h-[52px] flex items-center" :to="{path: `/about/intro`}">
                  <div>About</div>
                </router-link>
                <router-link class="menu" :to="{path: `/market/list`}">
                  <div>Wine NFT</div>
                </router-link>
                &lt;!&ndash;<router-link class="menu" :to="{path: `/market/list/sale`}">
                  <div>Wine NFT Buy Now</div>
                </router-link>&ndash;&gt;
                <router-link class="menu" :to="{path: `/market/membership/list`}">
                  <div>Membership NFT</div>
                </router-link>
                &lt;!&ndash;<router-link class="menu" :to="{path: `/market/membership/list/sale`}">
                  <div>Membership NFT Buy Now</div>
                </router-link>&ndash;&gt;
                <router-link class="menu" :to="{path: `/membership`}">
                  <div>Membership</div>
                </router-link>
                <router-link class="menu" :to="{path: `/cs/notice`}">
                  <div>Notice</div>
                </router-link>
                <router-link class="menu" :to="{path: `/cs/faq`}">
                  <div>FAQ</div>
                </router-link>
                <router-link class="menu" :to="{path: `/cs/guide`}">
                  <div>User Guide</div>
                </router-link>
                <router-link class="menu" :to="{path: `/users/mypage`}">
                  <div>Wine NFT</div>
                </router-link>
                <router-link class="menu" :to="{path: `/users/mypage/membership`}">
                  <div>Membership NFT</div>
                </router-link>
                <div class="menu text-[16px] !font-[Suit-Regular]" @click="isShowLoading=true">Activity</div>
                <router-link class="menu" :to="{path: `/users/mypage/profile`}">
                  <div>Profile</div>
                </router-link>
                &lt;!&ndash;<router-link class="menu" :to="{path: `/cs/guide`}">
                  <div>Wish List</div>
                </router-link>&ndash;&gt;
                &lt;!&ndash;<router-link class="menu" :to="{path: `/cs/guide`}">
                  <div>Wish List</div>
                </router-link>&ndash;&gt;

                  &lt;!&ndash; 비로그인(지갑 연결 안됨) 상태일 시 로그인 버튼 &ndash;&gt;
                  <IconButton
                      v-if="!auth.isLogin"
                      :content="'Connect'"
                      :class-list="'primary-btn w160'"
                      :icon="require('@/assets/images/icon/ic-wallet-white.png')"
                      class="login-btn"
                      :onClick="connectWalletLogin"
                      style="width: 100%;"
                  ></IconButton>
                  &lt;!&ndash; 민터 지갑 정상 연결 상태 시  NFT 생성 화면으로 가는 버튼 표시 &ndash;&gt;
                <router-link class="mt10" :to="{path: `/users/minter/create/step1`}">
                  <IconButton
                      v-if="auth.isLogin && auth.isMinter"
                      :content="'Create'"
                      :class-list="'primary-btn w160'"
                      :icon="require('@/assets/images/icon/ic-create-white.png')"
                      class="login-btn"
                      style="width: 100%;"
                  ></IconButton>
                </router-link>
              </div>-->
    </VueSlideToggle>

    <AlertModal
        title="알림"
        content="준비중입니다"
        v-if="isShowLoading"
        :isOpen="isShowLoading"
        :confirmClickBtn="() => {isShowLoading=false}"
        :button-class="'primary-btn'"

    />
  </div>
</template>
<script>

import getContractApi from "@/api/contract";
import IconButton from "@/components/buttons/IconButton";
import {VueSlideToggle} from 'vue-slide-toggle'
import AlertModal from "../../modals/AlertModal";
import _nav from "@/components/main/header/_nav";

export default {

  components: {AlertModal, IconButton, VueSlideToggle},
  created() {
    this.contract = getContractApi();
    this.setAuth()
    if (this.$route.query.keyword) {
      this.searchKeyword = this.$route.query.keyword
      this.mobileSearch = true
    }
  },
  mounted() {
    this.connectWalletLoginIfAvailable();

    const _this = this;
    const routeName = _this.$route.name
    _nav.forEach((nav) => {
      nav.active = false;


      if (nav.routeName && nav.routeName == routeName) {
        nav.active = true;
      }

      if (nav.children) {
        nav.children.forEach((child) => {
          child.active = false;
          if (child.routeName == routeName) {
            nav.active = true;
            child.active = true;
          }

          if (child.children) {
            child.children.forEach((grand) => {
              grand.active = false;
              if (grand.routeName == routeName) {
                nav.active = true;
                child.active = true;
                grand.active = true;
              }
            })
          }
        })
      }

      _this.navList.push(nav);

    })
    
  },
  data() {
    return {
      mobileSearch: false,
      contract: null,
      mobileMenuToggle: {
        isOpen: false
      },
      alertTitle: "알림",
      alertContent: "내용",
      alertClickAction: null,
      alertCheck: false,
      // 화면 리드로우 용
      auth: {
        isLogin: false,
        isMinter: false
      },
      searchKeyword: "",


      isShowLoading: false,

      navList: []
    }
  },
  methods: {
    wineMarketSearch() {
      this.$router.push('/market/list?page=1&order=3&sub_category_no=217&keyword=' + this.searchKeyword)

    },
    connectWalletLoginIfAvailable() {
      this.contract.getUserAddress().then((address) => {
        if (address) {
          this.connectWalletLogin(true);
        } else {
          localStorage.setItem('isLogin', false);
          localStorage.setItem('memberInfo', null);
          localStorage.setItem('isMinter', false);
          this.$auth.memberInfo = null
          this.setAuth();

        }
      });
    },
    basicAlert(text) {
      alert(text);
    },
    changeMenuState() {
      this.mobileMenuToggle.isOpen = !this.mobileMenuToggle.isOpen;
    },
    openDropdown(e) {
      // 일단 단일이라 대충
      if (e.target.className === "dropdown-menu-wrap") {

        e.target.children[1].children[0].style.height = '224px'
      }
    },
    closeDropdown(e) {
      e.target.children[1].children[0].style.height = '0px'
    },
    openDropdownMarket(e) {
      // 일단 단일이라 대충
      if (e.target.className === "dropdown-menu-wrap") {

        e.target.children[1].children[0].style.height = '118px'
      }
    },
    closeDropdownMarket(e) {
      e.target.children[1].children[0].style.height = '0px'
    },
    moveHome() {
      if (window.location.pathname === '/') {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
        this.$router.push('/')
      }
    },

    connectWalletLogin(slient) {
      // TODO:: 로그인시 어떤 변수값들을 처리해야되는지 몰라서 일단 localStorage로 일괄 저장 하였습니다. src/stroe/modules를 이용하여 처리하는 방식이 가장 깔끔할 것 같습니다.
      if (typeof window.ethereum !== 'undefined') {
        if (!slient)
          this.$store.commit('loading/init')
        // move here
        this.contract.login(e => {
          localStorage.setItem('isLogin', false)
          localStorage.setItem('memberInfo', null)
          localStorage.setItem('isMinter', false)
          this.$auth.memberInfo = null
          this.setAuth()
          if (e.name === "UserFriendlyError" && e.message) {
            this.alertOpen('알림', e.message, this.alertClose)
          } else {
            this.alertOpen('알림', "메타마스크 연결을 확인해 주세요!", this.alertClose)
          }
        }).then((result) => {
          localStorage.setItem('isLogin', true)
          localStorage.setItem('isMinter', false)
          this.$auth.memberInfo = result
          this.setAuth()
          return this.contract.isNegociant(result.account);
        }).then((isMinter) => {
          localStorage.setItem('isMinter', isMinter);
          this.setAuth();
        }).catch(e => {
          localStorage.setItem('isLogin', false)
          localStorage.setItem('memberInfo', null)
          localStorage.setItem('isMinter', false)
          this.$auth.memberInfo = null
          this.setAuth()
          if (e.name === "UserFriendlyError" && e.message) {
            this.alertOpen('알림', e.message, this.alertClose)
          } else {
            this.alertOpen('알림', "메타마스크 연결을 확인해 주세요!", this.alertClose)
          }
        }).finally(() => {
          if (!slient)
            this.$store.commit('loading/done')
        })

      } else {
        this.alertOpen('알림', '메타마스크를 설치해 주세요.', this.moveMetaMaskDownload)
      }
    },
    setAuth() {
      this.auth.isLogin = JSON.parse(localStorage.getItem('isLogin'));
      this.auth.isMinter = JSON.parse(localStorage.getItem('isMinter'));
      this.$auth.isLogin = this.auth.isLogin;
      this.$auth.isMinter = this.auth.isMinter;
    },

    moveMetaMaskDownload() {
      window.open('https://metamask.io/')
      this.alertClose()
    },
    alertClose() {
      this.alertCheck = false
    },
    alertOpen(title = '알림', content, clickAction) {
      this.alertTitle = title
      this.alertContent = content
      this.alertClickAction = clickAction
      this.alertCheck = true

    },
    mobileSearchOn() {
      this.mobileSearch = true
    },
    mobileSearchOff() {
      this.mobileSearch = false
    }
  },

}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global-header.scss";
</style>
